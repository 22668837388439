<template>
    <div>
        <v-autocomplete
			v-model="AgentCode"
			:items="items"
			:search-input.sync="search_agents"
			:rules="rules"
			:loading="isLoading"
			:item-text="getItemTextLabel"
			item-value="AgentCode"
			:label="label"
			:placeholder="placeholder"
			:no-data-text="noDataText"
			prepend-icon="fas fa-user"
			auto-select-first
			validate-on-blur
			cache-items
			:return-object="returnObject"
			clearable
			v-bind="$attrs"
			@focus="preloadItems"
		/>
    </div>
</template>

<script>
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
	inheritAttrs: false,
	props: {
		value: {},
		label: {},
		placeholder: {},
		returnObject: {},
		filters: {},
		rules: {},
		division: {},
		addSelf: {},
		preloadAgents: { type: Boolean, default: false },
	},
    data () {
        return {
            isLoading: false,
            search_agents: null,
            AgentCode: this.value,
            items: [],
            dont_search: false,
        }
    },
    mounted () {
        if (this.value !== null) {
            this.findAgent(this.value)
        }
    },
	computed: {
		noDataText () {
			return this.isLoading
				? 'Data is loading...'
				: 'No data available'
		},
	},
    methods: {
		preloadItems () {
			if (!this.preloadAgents) { return }
			if (this.items.length) { return }
			this.agentDownlineSearch(null, true)
		},
        updateValue: function(v, emit) {
            this.setDontSearch()
            this.AgentCode = v;
            if (emit === true) {
                this.$emit('input', v);
            }
        },
        getItemTextLabel: function(item) {
            return (item.AgentName + " - " + item.LeadershipLevel + ' (' + item.AgentCode + ')')
        },
        findAgent: function(agent) {
			let agentcode = agent
			if (typeof agent === 'object' && agent !== null) {
				agentcode = agent.AgentCode
			}
            this.isLoading = true
            // Lazily load input items
            var g = this
            QuilityAPI.getAgentStateless(agentcode).then(function(json) {
                g.setDontSearch()
                g.$set(g, 'items', [json])
                g.isLoading = false
            }).catch(function() {
                g.isLoading = false
            });
        },
        //if we just set the agent code for the v-model we don't want to search again for the same agent.
        setDontSearch: function() {
            var g = this
            g.dont_search = true
            setTimeout(function() {
                g.dont_search = false
            }, 1000)
        },
		agentDownlineSearch: debounce(function (search, isPreload) {
            // if we just set the agent code for the v-model we don't want to search again for the same agent.
            if (this.dont_search) { return }

            if (!isPreload && (!search || search.length < 3)) {
                this.isLoading = false
                return
            }

            // Items have already been loaded
            //if (this.items.length > 0) return

            // Items have already been requested
            //if (this.isLoading) return

            // Lazily load input items
            this.isLoading = true
            const options = {
                search,
                itemsPerPage: isPreload ? 30 : 100,
                page: 1,
                division: this.division,
                ...this.filters,
            }
            QuilityAPI.getAgentDownlineSearch(null, options, null, true, (json) => {
				let data = json.data
				if (isPreload && data.length > 30) {
					data = data.slice(0, 30)
				}
                if (this.addSelf) {
					data = [
						...data,
						{
							AgentName: this.user.Agent.AgentName,
							LeadershipLevel: this.user.Agent.LeadershipLevel,
							AgentCode: this.user.Agent.AgentCode,
						},
					]
                }
                this.$set(this, 'items', data)
                this.isLoading = false
            })
        }, 250),
    },
    watch: {
        'AgentCode': function(v) {
            this.updateValue(v, true);
        },
        'value': function(v) {
            this.dont_search = true
            this.findAgent(v)
            this.updateValue(v, false);
        },
        search_agents (val) {
			this.agentDownlineSearch(val)
		},
    }
}
</script>
